<template>
  <div>
    <v-breadcrumbs :items="items"></v-breadcrumbs>
    <v-divider></v-divider>
    <add-new-rtb-rule
      @create="createRtbRule($event)"
      class="mt-5"
      :single_rtbRule="single_rtbRule"
      @update="updateRule($event)"
    />
  </div>
</template>

<script>
import AddNewRtbRule from "../../../components/Programmatic/AddNewRtbRule.vue";

export default {
  data: () => ({
    items: [
      {
        text: "RTB Rules",
        disabled: false,
        href: "#/audiences/rules",
      },
      {
        text: "Add/Edit RTB Rule",
        disabled: true,
        href: "#",
      },
    ],
    error: null,
  }),
  components: {
    AddNewRtbRule,
  },
  computed: {
    dsp_rtb_standards() {
      return this.$store.state.dsp.rtb_standards;
    },
    single_rtbRule() {
      return this.$store.state.dsp.single_rtbRule;
    },
  },
  async mounted() {
    if (this.$route.name === "edit-rtb-rule") {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/singleRule", this.$route.params.id);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    }
  },
  methods: {
    async createRtbRule(body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/createRule", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "RTB rule created successfully!",
          group: "success",
        });
        this.$router.push("/audiences/rules");
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async updateRule(body) {
      let combined_data = {
        rtb_id: this.$route.params.id,
        body: body,
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/updateRule", combined_data);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "RTB rule updated successfully!",
          group: "success",
        });
        await this.$router.push("/audiences/rules");
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>