<template>
  <v-form v-model="valid" lazy-validation class="campaign-form" ref="rtb_form">
    <v-row>
      <v-col>
        <general-card class="pa-5">
          <h5 class="mb-2">Rule Name <span class="error--text">*</span></h5>
          <v-text-field
            v-model="rule_name"
            outlined
            dense
            class="mb-3"
            :rules="[(v) => !!v || 'Rule Name is required!']"
            hide-details="auto"
          ></v-text-field>

          <h5 class="mb-2">Customer ID</h5>
          <v-text-field
            v-model="customer_id"
            outlined
            dense
            class="mb-3"
            disabled
            hide-details="auto"
          ></v-text-field>

          <h5 class="mb-2">RTB Specification <span class="error--text">*</span></h5>
          <v-select
            outlined
            dense
            label="Indicate your RTB specification here"
            v-model="rtb_specification"
            :items="rtb_specifications"
            class="mb-3"
            :rules="[(v) => !!v || 'RTB Specification is required!']"
            hide-details="auto"
          ></v-select>

          <h5 class="mb-2">Operator <span class="error--text">*</span></h5>
          <v-select
            outlined
            dense
            label="Specify your operator"
            :items="operators"
            v-model="operator"
            class="mb-3"
            :rules="[(v) => !!v || 'Operator is required!']"
            hide-details="auto"
          ></v-select>

          <v-row>
            <v-col cols="4">
              <h5 class="mb-2">Operand <span class="error--text">*</span></h5>
              <v-text-field
                outlined
                dense
                label=""
                class="mb-3"
                v-model="operand"
                :rules="[(v) => !!v || 'Operand is required!']"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <h5 class="mb-2">Operand Type <span class="error--text">*</span></h5>
              <div class="d-flex">
                <v-select
                  outlined
                  dense
                  label="Operand Type"
                  class="mb-3"
                  :items="operand_types"
                  v-model="operand_type"
                  :rules="[(v) => !!v || 'Operand Type is required!']"
                  hide-details="auto"
                ></v-select>
              </div>
            </v-col>
          </v-row>
        </general-card>
      </v-col>

      <v-col>
        <general-card class="pa-5">
          <h5 class="mb-2">Operand Ordinal <span class="error--text">*</span></h5>
          <v-select
            outlined
            dense
            v-model="operand_ordinal"
            class="mb-3"
            :rules="[(v) => !!v || 'Operand Ordinal is required!']"
            :items="operand_ordinals"
            hide-details="auto"
          ></v-select>

          <h5 class="mb-2">Description</h5>
          <v-text-field
            class="mb-3"
            outlined
            dense
            label=""
            chips
            multiple
            v-model="description"
            hide-details="auto"
          >
          </v-text-field>
          <h5 class="mb-2">Sub ID</h5>
          <v-text-field
            outlined
            dense
            label=""
            v-model="sub_id"
            chips
            multiple
            class="mb-3"
            hide-details="auto"
          ></v-text-field>

          <div class="d-flex align-center">
            <v-checkbox
              outlined
              dense
              v-model="rtb_required"
              class="mb-3"
              hide-details="auto"
              label="RTB Required"
            ></v-checkbox>
          </div>

          <!-- <h5 class="mb-2">Operand List ID <span class="error--text">*</span></h5>
          <v-text-field
            outlined
            dense
            label=""
            v-model="operand_list_id"
            chips
            multiple
            class="mb-4"
            :rules="[(v) => !!v || 'Operand List ID is required!']"
          ></v-text-field> -->

          <v-card-actions class="d-flex justify-end">
            <v-btn
              depressed
              class="primary"
              @click="submit"
              v-if="$route.name == 'add-rtb-rule'"
              >Submit</v-btn
            >
            <v-btn class="primary" text @click.stop="updaterule" dark v-else>
              Update
            </v-btn>
          </v-card-actions>
        </general-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import GeneralCard from "../../components/Layouts/Cards/GeneralCard.vue";

export default {
  components: {
    GeneralCard,
  },
  data: () => ({
    rule_name: null,
    customer_id: 1,
    rtb_specification:null,
    rtb_specifications: [
      "request.app.id",
      "request.app.name",
      "request.app.bundle",
      "request.app.domain",
      "request.app.storeurl",
      "request.app.cat",
      "request.app.sectioncat",
      "request.app.ver",
      "request.app.privacypolicy",
      "request.app.paid",
      "request.app.keywords",
      "openrtb.bcat",
      "openrtb.device.carrier",
      "openrtb.device.make",
      "openrtb.device.os",
      "openrtb.imp[0].banner.pos",
      "openrtb.imp.metric.type",
      "openrtb.imp.metric.value",
      "app.publisher.id",
      "openrtb.imp[0].adx_ext.billing_id",
      "openrtb.device.geo.country",
      "openrtb.device.id",
    ],
    operator: null,
    operand: null,
    operand_type: null,
    operand_types: ["INTEGER", "DECIMAL", "STRING"],
    operand_ordinal:null,
    operand_ordinals: ["LIST", "SCALAR"],
    description: null,
    sub_id: 1,
    rtb_required: false,
    operand_list_id: null,
    valid: true,
    operators: [
      "INRANGE",
      "DOMAIN",
      "EXISTS",
      "GREATER_THAN",
      "GREATER_THAN_EQUALS",
      "LESS_THAN",
      "LESS_THAN_EQUALS",
      "NOT_DOMAIN",
      "NOT_EQUALS",
      "NOT_EXISTS",
      "NOT_INRANGE",
      "NOT_INTERSECTS",
      "INTERSECTS",
      "EQUALS",
      "INRANGE",
      "MEMBER",
      "NOT_MEMBER",
      "NOT_REGEX",
      "NOT_STRINGIN",
      "OR",
      "REGEX",
      "STRINGIN",
    ],
  }),
  props: {
    single_rtbRule: {
      type: Object,
    },
  },

  methods: {
    async submit() {
      await this.$refs.rtb_form.validate();

      if (!this.valid) return;

      let body = {
        name: this.rule_name,
        customer_id: this.customer_id,
        rtb_specification: this.rtb_specification,
        operator: this.operator,
        operand: this.operand,
        operand_type: this.operand_type,
        operand_ordinal: this.operand_ordinal,
        description: this.description,
        sub_id: this.sub_id,
        rtb_required: this.rtb_req,
        operand_list_id: this.operand_list_id,
      };
      this.$emit("create", body);
    },

    async updaterule() {
      let body = {
        name: this.rule_name,
        customer_id: this.customer_id,
        rtb_specification: this.rtb_specification,
        operator: this.operator,
        operand: this.operand,
        operand_type: this.operand_type,
        operand_ordinal: this.operand_ordinal,
        description: this.description,
        sub_id: this.sub_id,
        rtb_required: this.rtb_required,
        operand_list_id: this.operand_list_id,
      };
      this.$emit("update", body);
    },
  },

  watch: {
    single_rtbRule() {
      this.rule_name = this.single_rtbRule.name ? this.single_rtbRule.name : null;
      this.customer_id = this.single_rtbRule.customer_id
        ? this.single_rtbRule.customer_id
        : null;
      this.rtb_specification = this.single_rtbRule.rtbspecification
        ? this.single_rtbRule.rtbspecification
        : null;
      this.operator = this.single_rtbRule.operator ? this.single_rtbRule.operator : null;
      this.operand = this.single_rtbRule.operand ? this.single_rtbRule.operand : null;
     
      if (this.single_rtbRule.operand_type == "I") {
        this.operand_type = "INTEGER";
      }else if(this.single_rtbRule.operand_type == "S" || this.single_rtbRule.operand_type == "STRING") {
        this.operand_type= "STRING";
      }else if (this.single_rtbRule.operand_type == "D"){
        this.operand_type= "DECIMAL";
      }

      if (this.single_rtbRule.operand_ordinal == "L" || this.single_rtbRule.operand_ordinal == "LIST" ) {
        this.operand_ordinal = "LIST";
      }else if(this.single_rtbRule.operand_ordinal == "S" || this.single_rtbRule.operand_ordinal == "SCALAR" ) {
        this.operand_ordinal = "SCALAR";
      }
      
      this.description = this.single_rtbRule.description
        ? this.single_rtbRule.description
        : null;
      this.sub_id = this.single_rtbRule.sub_id ? this.single_rtbRule.sub_id : null;
      this.rtb_required = this.single_rtbRule.rtb_required
        ? this.single_rtbRule.rtb_required
        : null;
      this.operand_list_id = this.single_rtbRule.operand_list_id
        ? this.single_rtbRule.operand_list_id
        : null;
    },
  },

  computed: {
    rtb_req() {
      let value = this.rtb_required ? 1 : 0;
      return value;
    },
  },
};
</script>
